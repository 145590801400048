import styles from "./Hero.module.css";
import React from "react";
import { socialIcons } from "../../utils/data";

const Hero = () => {
  return (
    <div className={styles.heroSection}>
      <h1>Ryan Park</h1>
      <h1>I work for the web</h1>
      <br />
      <p>
        I'm a global software developer who loves making ideas come true through
        digital experiences.{" "}
      </p>
      <div className={styles.heroIcons}>
        <ul className={styles.sidebarIcons}>
          {socialIcons.map((item) => {
            const { icon, url } = item;
            return (
              <li>
                <a href={url} target="_blank">
                  {icon}
                </a>
              </li>
            );
          })}
        </ul>
      </div>
      <a href="mailto:ryan.developer99@gmail.com">
        <button className={styles.btn}>Get In Touch</button>
      </a>
    </div>
  );
};

export default Hero;
